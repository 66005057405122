<script setup lang="ts">
import * as yup from 'yup'
import { useToast } from 'vue-toastification'
import type { AuthView } from '@base/types'

const emit = defineEmits<{
  (e: 'changeView', view: AuthView): void
  (e: 'close'): void
}>()

const toast = useToast()
const { sendResetPasswordEmail } = useAuth()
const { emailValidation } = useValidations()
const { $t } = useI18n()

const validationSchema = yup.object({
  email: emailValidation,
})

const { meta, isSubmitting, handleSubmit } = useForm({ validationSchema })

const onSubmit = handleSubmit(async values => {
  const error = await sendResetPasswordEmail(values.email)
  if (error)
    return toast.error(String($t('auth.authError')))

  toast.success(String($t('auth.emailSended')))
  emit('close')
})
</script>

<template>
  <div>
    <form @submit="onSubmit">
      <div flex flex-col gap-16px>
        <h4 :data-test="getCypressId('forgot-password-header')" heading4 font-800 my-16px>
          {{ $t('auth.forgotPassword') }}
        </h4>
        <FInputVee name="email" type="text" :label="String($t('auth.emailLabel'))" :placeholder="String($t('auth.emailPlaceholder'))" cypress-prefix="forgotten" />
      </div>
      <FButton name="forgot-password-continue" :disabled="!meta.dirty || !meta.valid" variant="primary" size="lg" w-full mt-48px :loading="isSubmitting" type="submit">
        {{ $t('auth.continue') }}
      </FButton>
    </form>
    <div mt-32px paragraph-sm text-center>
      {{ $t('auth.ifAccountExist') }}<br><button type="button" golden-link @click="emit('changeView', 'login')">
        {{ $t('auth.loginButton') }}
      </button>
    </div>
  </div>
</template>
