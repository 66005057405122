<script setup lang="ts">
import { useForm } from 'vee-validate'
import * as yup from 'yup'
import { useToast } from 'vue-toastification'
import type { AuthCredentials, AuthView } from '@base/types'

defineProps<{
  disableLinks?: boolean
}>()

const emit = defineEmits<{
  (e: 'changeView', view: AuthView): void
  (e: 'close'): void
}>()

const { $t, $switchRoute } = useI18n()
const toast = useToast()
const { loginWithPassword } = useAuth()
const { emailValidation } = useValidations()
const { waitUntilResolve } = useUser()
const { mobileMenuOpen } = useNavigation()

const validationSchema = yup.object({
  email: emailValidation,
  password: yup.string().required(String($t('auth.passwordPlaceholder'))),
})

const { meta, handleSubmit } = useForm({ validationSchema })
const isSubmitting = ref()

const onSubmit = handleSubmit(async values => {
  isSubmitting.value = true
  try {
    await loginWithPassword(values as AuthCredentials)
    await waitUntilResolve()

    toast.success($t('auth.loginSuccess'))
    $switchRoute('/profile/overview')

    emit('close')
    mobileMenuOpen.value = false
  }
  catch (e: any) {
    console.error(e)
    toast.error(e.message)
    isSubmitting.value = false
  }
})
</script>

<template>
  <div>
    <form @submit="onSubmit">
      <div flex flex-col gap-16px>
        <h4 :data-test="getCypressId('login-header')" heading4 font-800 my-16px>
          {{ $t('auth.loginHeader') }}
        </h4>

        <FInputVee name="email" cypress-prefix="login" type="text" :label="String($t('auth.emailLabel'))" :placeholder="String($t('auth.emailPlaceholder'))" />
        <FInputVee name="password" type="password" :label="String($t('auth.passwordLabel'))" :placeholder="String($t('auth.passwordPlaceholder'))" :show-strength="false" />
        <div v-if="!disableLinks" text-right>
          <button type="button" paragraph-sm to="/" golden-link @click="emit('changeView', 'forgottenPassword')">
            {{ $t('auth.forgotPassword') }}
          </button>
        </div>
      </div>
      <FButton name="login-login" :disabled="!meta.dirty || !meta.valid" variant="primary" size="lg" w-full mt-48px :loading="isSubmitting" type="submit">
        {{ $t('auth.loginHeader') }}
      </FButton>
    </form>

    <div v-if="!disableLinks" mt-32px paragraph-sm text-center>
      {{ $t('auth.noAccountPromptLogin') }}<br><button type="button" golden-link @click="emit('changeView', 'register')">
        {{ $t('auth.registerLinkLogin') }}
      </button>
    </div>
  </div>
</template>
